import { FormEvent, useEffect, useState } from 'react';
import Heading from '@atoms/Heading/Heading';
import Modal, { InternalNavigationType } from '@organisms/Modal/Modal';
import Text from '@atoms/Text/Text';
import Link from '@atoms/Link/Link';
import useTranslation from 'next-translate/useTranslation';
import paths from '@constants/paths';
import {
  changePassword,
  checkB2bCustomerBySapId,
  checkExistingCustomer,
  validateEmailToken,
  verifySmsToken,
} from '@api/interfaces/passwordResetApi';
import encrypt from '@helpers/encrypt';
import logger from '@logger';
import useCustomRouter from '@hooks/useCustomRouter';
import LostPasswordTabContent from '@organisms/LostPassword/LostPasswordTabContent';
import {
  trackCreateNewPassword,
  trackCustomerNumber,
  trackErrorMessage,
  trackRecoveryMethod,
  trackVerifyEmail,
  trackVerifyPhone,
  trackVerifySmsCode,
} from '@helpers/analyticsHelpers/trackLostPassword';
import Button from '@atoms/Button/Button';
import { StyledContentWrapper, StyledFooter, StyledForm, StyledSubmitButton } from './LostPassword.styles';
import useCustomer from '@hooks/useCustomer';

const initialFormState = {
  b2b: '',
  b2c: '',
  email: '',
  phone: '',
  verifyField: '',
  passwordRecoveryMethod: 'email',
  smsCode: '',
  password: '',
  repeatPassword: '',
  rememberMe: true,
  encryptedToken: '',
  uid: '',
};

interface Props {
  onCloseHandler: () => void;
  tab: 'b2c' | 'b2b';
}

const LostPassword = ({ onCloseHandler, tab }: Props) => {
  const { t } = useTranslation('lostPassword');
  const router = useCustomRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState(tab || 'b2c');
  const [formValues, setFormValues] = useState<LostPasswordFormType>(initialFormState as LostPasswordFormType);
  const [errorMsg, setErrorMsg] = useState('');
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const initialNavigationState = {
    currentStep: 1,
    totalSteps: 4,
  };
  const [internalNavigation, setInternalNavigation] = useState<InternalNavigationType>(initialNavigationState);
  const { customer, refreshCustomer } = useCustomer();

  const onNavigationClick = (step: number) => {
    setErrorMsg('');
    if (step < 1) {
      router.replace(
        { pathname: router.pathname, query: { ...router.query, tab: activeTab } },
        { pathname: paths.USER_LOGIN }
      );
    } else {
      setInternalNavigation({ ...internalNavigation, currentStep: step });
    }
  };

  const errorHandler = async (error: Response, step: number) => {
    logger.error({
      error: `Lost password error, step: ${step}`,
    });

    const msg = t(`error->${Object.values(await error.json())[0] || 'register.error.default'}`);
    trackErrorMessage(activeTab as 'b2b' | 'b2c', msg);
    setErrorMsg(msg);
  };

  const onSubmitSuccess = (data: any, username: string) => {
    const { customerFound, customerHasMembership, phone, email, customerNeedContactSupport } = data;
    if (customerFound && (phone || email)) {
      setFormValues({
        ...formValues,
        [activeTab]: username,
        email,
        phone,
        passwordRecoveryMethod: email ? 'email' : 'phone',
      });
      trackCustomerNumber(activeTab as 'b2c' | 'b2b');
      setInternalNavigation({ ...internalNavigation, currentStep: 2 });
    } else if (customerHasMembership || customerNeedContactSupport) {
      setErrorMsg(t('step1->error->noEmailPhone'));
    } else if (activeTab === 'b2c') {
      setShowCreateAccountModal(true);
    } else {
      setErrorMsg(t('b2bNoAccountError'));
    }
    setIsSubmitting(false);
  };

  const b2bSubmitHandler = async (orgNr: string) => {
    const res: any = await checkB2bCustomerBySapId(orgNr);
    onSubmitSuccess(res.data, orgNr);
  };

  const b2cSubmitHandler = async (ssn: string) => {
    const encryptedSsn = await encrypt(ssn);
    const res: any = await checkExistingCustomer({
      socialSecurityNumber: encryptedSsn.str,
      socialSecurityNumberKey: encryptedSsn.key,
    });
    onSubmitSuccess(res.data, ssn);
  };

  const onSubmitStep1 = async (username: string) => {
    try {
      if (activeTab === 'b2b') {
        await b2bSubmitHandler(username);
      } else {
        await b2cSubmitHandler(username);
      }
    } catch (error) {
      if (error instanceof Response) {
        await errorHandler(error, 1);
      }
      setIsSubmitting(false);
    }
  };

  const onSubmitStep2 = () => {
    setIsSubmitting(false);
    trackRecoveryMethod(activeTab as 'b2b' | 'b2c', formValues.passwordRecoveryMethod);
    setInternalNavigation({ ...internalNavigation, currentStep: 3 });
  };

  const onSubmitStep3 = async () => {
    const { verifyAccountWithEmail, verifyAccountWithPhone } = await import('@api/interfaces/customerApi');
    const encryptedUsername = await encrypt(formValues[activeTab as 'b2b' | 'b2c']);
    const { passwordRecoveryMethod, verifyField } = formValues;
    const formData = { userNameCipherKey: encryptedUsername.key, encryptedUserName: encryptedUsername.str };
    try {
      if (passwordRecoveryMethod === 'email') {
        await verifyAccountWithEmail({
          ...formData,
          email: verifyField,
        });
        trackVerifyEmail(activeTab as 'b2c' | 'b2b');
        setInternalNavigation({ ...internalNavigation, currentStep: 4 });
      } else {
        const response: any = await verifyAccountWithPhone({
          ...formData,
          phone: verifyField,
        });
        const { status, encryptedToken, uid } = response.data;

        if (uid && encryptedToken && status) {
          setFormValues({ ...formValues, encryptedToken, uid });
          trackVerifyPhone(activeTab as 'b2b' | 'b2c');
          setInternalNavigation({ ...internalNavigation, currentStep: 5 });
        } else {
          setErrorMsg(t('error->register.phone.noMatch'));
        }
      }
    } catch (error) {
      if (error instanceof Response) {
        await errorHandler(error, 3);
      }
    }
    setIsSubmitting(false);
  };

  const onSubmitStep4 = () => {
    setIsSubmitting(false);
    onCloseHandler();
  };

  const onSubmitStep5 = async () => {
    const encryptedPassword = await encrypt(formValues.password);
    const encryptedRepeatPassword = await encrypt(formValues.repeatPassword);
    try {
      if (formValues.smsCode) {
        const response: any = await verifySmsToken({
          encryptedToken: formValues.encryptedToken,
          uid: formValues.uid,
          pwd: encryptedPassword.str,
          pwdKey: encryptedPassword.key,
          token: formValues.smsCode,
          rememberMe: formValues.rememberMe,
        });
        const { status } = response.data;
        if (status) {
          await refreshCustomer();
          trackVerifySmsCode(activeTab as 'b2b' | 'b2c');
          trackCreateNewPassword(activeTab as 'b2b' | 'b2c', formValues.rememberMe);
          setInternalNavigation({ ...internalNavigation, currentStep: 6 });
        } else {
          setErrorMsg(t('error->updatePwd.smsToken.invalid'));
        }
      } else {
        const token = router?.query?.token;
        await changePassword(
          token as string,
          encryptedPassword.str,
          encryptedRepeatPassword.str,
          formValues.rememberMe,
          encryptedPassword.key,
          encryptedRepeatPassword.key
        );
        await refreshCustomer();
        trackCreateNewPassword(customer?.isB2BCustomer ? 'b2b' : 'b2c', formValues.rememberMe);
        setInternalNavigation({ ...internalNavigation, currentStep: 6 });
      }
    } catch (error) {
      if (error instanceof Response) {
        await errorHandler(error, 5);
      }
    }
    setIsSubmitting(false);
  };

  const onSubmitStep6 = () => {
    setIsSubmitting(false);
    onCloseHandler();
  };

  const onSubmitStep7 = () => {
    setIsSubmitting(false);
    setInternalNavigation({ ...internalNavigation, currentStep: 1 });
    router.replace({ pathname: router.pathname, query: router.query }, paths.LOST_PASSWORD, { shallow: true });
  };

  const validateToken = async (token: string) => {
    setIsSubmitting(true);
    try {
      await validateEmailToken(token);
      setInternalNavigation({ ...internalNavigation, currentStep: 5 });
    } catch (error) {
      if (error instanceof Response) {
        await errorHandler(error, 4);
        setInternalNavigation({ ...internalNavigation, currentStep: 7 });
      }
    }
    setIsSubmitting(false);
  };

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMsg('');

    switch (internalNavigation.currentStep) {
      case 1:
        await onSubmitStep1(formValues[activeTab as 'b2b' | 'b2c']);
        break;
      case 2:
        onSubmitStep2();
        break;
      case 3:
        await onSubmitStep3();
        break;
      case 4:
        onSubmitStep4();
        break;
      case 5:
        await onSubmitStep5();
        break;
      case 6:
        onSubmitStep6();
        break;
      case 7:
        onSubmitStep7();
        break;
      default:
    }
  };

  useEffect(() => {
    if (router.isReady) {
      const string = router.asPath.split('?')[0];
      if (string.startsWith(paths.RESET_PASSWORD) || string.startsWith(paths.RESET_PASSWORD_B2B)) {
        if (router?.query?.token) {
          validateToken(router.query.token as string);
        } else {
          router.replace(
            { pathname: router.pathname, query: router.query },
            string.startsWith(paths.RESET_PASSWORD) ? paths.LOST_PASSWORD : paths.LOST_PASSWORD_B2B,
            { shallow: true }
          );
        }
      } else if (router.query?.username) {
        setFormValues({ ...formValues, [activeTab]: router.query.username });
        setIsSubmitting(true);
        onSubmitStep1(router.query.username as string);
      } else if (string.startsWith(paths.LOST_PASSWORD_B2B)) {
        setActiveTab('b2b');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  useEffect(() => {
    // Make sure to clear error state when form values or active tab changes
    setErrorMsg('');
  }, [formValues, activeTab]);

  return (
    <>
      <Modal
        size="medium"
        hasCloseIcon
        onClose={onCloseHandler}
        internalNavigation={internalNavigation}
        navigationCallback={onNavigationClick}
        hasMinHeight
      >
        <StyledContentWrapper gap={20}>
          <Heading variant="h2" size="small">
            {t(`step${internalNavigation.currentStep}->${formValues.passwordRecoveryMethod}->heading`)}
          </Heading>
          {activeTab === 'b2b' && internalNavigation.currentStep <= 3 && (
            <Text type="subtitle" size="small">
              {t('b2bSubHeading')}
            </Text>
          )}

          <StyledForm onSubmit={onSubmitHandler}>
            <LostPasswordTabContent
              isSubmitting={isSubmitting}
              formValues={formValues}
              setFormValues={setFormValues}
              mode={activeTab as 'b2b' | 'b2c'}
              step={internalNavigation.currentStep}
              errorMsg={errorMsg}
            />

            <StyledSubmitButton type="submit" isSubmitting={isSubmitting}>
              {t(`step${internalNavigation.currentStep}->${formValues.passwordRecoveryMethod}->submitButton`)}
            </StyledSubmitButton>
          </StyledForm>
          <StyledFooter>
            {internalNavigation.currentStep === 1 && (
              <Text type="body" size="small">
                {`${activeTab === 'b2c' ? t('b2bCustomer') : t('b2cCustomer')}`}{' '}
                <Link
                  onClick={() => setActiveTab(activeTab === 'b2c' ? 'b2b' : 'b2c')}
                  href={activeTab === 'b2c' ? paths.LOST_PASSWORD_B2B : paths.LOST_PASSWORD}
                >
                  {t('resetPassword')}
                </Link>
              </Text>
            )}
            <Text type="body" size="small">
              {t('infoTextHelp')} <Link href={paths.external.ECOMMERCE_CONTACT}>{t('infoTextContact')}</Link>
            </Text>
          </StyledFooter>
        </StyledContentWrapper>
      </Modal>
      {showCreateAccountModal && (
        <Modal size="small" onClose={() => setShowCreateAccountModal(false)}>
          <StyledContentWrapper gap={20}>
            <Heading variant="h2" size="small">
              {t('modal->title')}
            </Heading>

            <Text type="body">{t('modal->text')}</Text>

            <Button theme="secondary" onClick={() => setShowCreateAccountModal(false)}>
              {t('modal->cancelButton')}
            </Button>
            <Button
              theme="primary"
              onClick={() => {
                router.replace({
                  pathname: paths.REGISTER_B2C_IDENTIFY,
                });
              }}
            >
              {t('modal->createAccountButton')}
            </Button>
          </StyledContentWrapper>
        </Modal>
      )}
    </>
  );
};

export default LostPassword;
