import InputTooltip from '@organisms/InputTooltip/InputTooltip';
import { loginRegex } from '@helpers/validationRegex';
import formatSSN from '@helpers/formatSSN';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import Text from '@atoms/Text/Text';
import Radio from '@atoms/Radio/Radio';
import InputField from '@atoms/InputField/InputField';
import ErrorMessage from '@molecules/Form/ErrorMessage';
import atLeastOneUppercaseAndLowercase from '@helpers/regexp/atLeastOneUppercaseAndLowercase/atLeastOneUppercaseAndLowercase';
import { StyledErrorList } from '@pages/registrera/privat/skapa/AccountCreateB2CPage.styles';
import React, { useMemo } from 'react';
import { StyledCheckBox, StyledContentWrapper } from './LostPasswordTabContent.styles';
import Info from '@icons/info.svg';
import onlyAllowedSpecialCharacters from '@helpers/regexp/onlyAllowedSpecialCharacters/onlyAllowedSpecialCharacters';

interface props {
  formValues: LostPasswordFormType;
  setFormValues: (values: LostPasswordFormType) => void;
  mode?: 'b2c' | 'b2b';
  step: number;
  isSubmitting: boolean;
  errorMsg?: string;
}

const LostPassword = ({ mode, formValues, setFormValues, step, isSubmitting, errorMsg }: props) => {
  const { t } = useTranslation('lostPassword');
  const passwordMinLength = 6;
  const passwordMaxLength = 30;

  const isMatching = useMemo(() => {
    if (formValues.password !== formValues.repeatPassword) {
      return t('account:customer->password->passwordMatchError');
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.password, formValues.repeatPassword]);

  return (
    <>
      {!isSubmitting && (
        <div>
          {mode && step === 1 && (
            <InputTooltip
              required
              inputValue={formValues[mode]}
              inputName={mode}
              iconProps={{ svg: Info, size: 16 }}
              inputAriaLabel={t(`step1->${mode}->placeholder`)}
              inputType="text"
              placeholder={t(`step1->${mode}->placeholder`)}
              tooltipContent={t(`step1->${mode}->tooltip`)}
              inputOnChange={(e) => setFormValues({ ...formValues, [mode]: e.target.value })}
              tooltipAlign="right"
              inputOnBlur={() => setFormValues({ ...formValues, [mode]: formatSSN(formValues[mode]) })}
              pattern="[0-9]*"
              customValidator={(val) => {
                if (!loginRegex[mode].test(val)) {
                  return t(`step1->error->${mode}`);
                }
                return '';
              }}
            />
          )}

          {step === 2 && (
            <StyledContentWrapper gap={28}>
              <Text type="body">{t(`step2->title`)}</Text>

              {formValues.email && (
                <StyledContentWrapper gap={10}>
                  <Text type="body">{t(`step2->radioEmail`)}</Text>
                  <Radio
                    checked={formValues.passwordRecoveryMethod === 'email'}
                    onChange={() => setFormValues({ ...formValues, passwordRecoveryMethod: 'email', verifyField: '' })}
                    id="emailRadio"
                  >
                    <Text type="subhead">{formValues.email}</Text>
                  </Radio>
                </StyledContentWrapper>
              )}

              {formValues.phone && (
                <StyledContentWrapper gap={10}>
                  <Text type="body">{t(`step2->radioPhone`)}</Text>
                  <Radio
                    checked={formValues.passwordRecoveryMethod === 'phone'}
                    onChange={() => setFormValues({ ...formValues, passwordRecoveryMethod: 'phone', verifyField: '' })}
                    id="phoneRadio"
                  >
                    <Text type="subhead">{formValues.phone}</Text>
                  </Radio>
                </StyledContentWrapper>
              )}
            </StyledContentWrapper>
          )}

          {step === 3 && (
            <StyledContentWrapper gap={20}>
              <Text type="body">{t(`step3->${formValues.passwordRecoveryMethod}->title`)}</Text>

              <StyledContentWrapper gap={4}>
                <Text type="body">{t(`step3->${formValues.passwordRecoveryMethod}->label`)}</Text>
                <Text type="subhead">{formValues[formValues.passwordRecoveryMethod]}</Text>
              </StyledContentWrapper>

              <InputField
                type={formValues.passwordRecoveryMethod === 'phone' ? 'number' : 'email'}
                name={formValues.passwordRecoveryMethod}
                aria-label={t(`step3->${formValues.passwordRecoveryMethod}->placeholder`)}
                placeholder={t(`step3->${formValues.passwordRecoveryMethod}->placeholder`)}
                value={formValues.verifyField}
                onChange={(e) => setFormValues({ ...formValues, verifyField: e.target.value })}
                required
              />
            </StyledContentWrapper>
          )}

          {step === 4 && (
            <StyledContentWrapper gap={28}>
              <Text type="body">{t('step4->email->infoText')}</Text>
            </StyledContentWrapper>
          )}

          {step === 5 && (
            <StyledContentWrapper gap={20}>
              {formValues.passwordRecoveryMethod === 'phone' && (
                <>
                  <Text type="body">
                    <Trans
                      i18nKey="lostPassword:step5->phone->infoText"
                      values={{ phone: formValues.verifyField }}
                      components={[<strong key={1}>{}</strong>]}
                    />
                  </Text>
                  <InputField
                    required
                    type="number"
                    name="verifySMSCode"
                    placeholder={t('step5->phone->smsCodePlaceholder')}
                    aria-label={t('step5->phone->smsCodePlaceholder')}
                    maxLength={6}
                    value={formValues.smsCode}
                    onChange={(e) => setFormValues({ ...formValues, smsCode: e.target.value })}
                  />
                </>
              )}

              <InputField
                type="password"
                name="password"
                placeholder={t('step5->passwordPlaceholder')}
                value={formValues.password}
                onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                required
                passwordVisibilityToggle
                aria-label={t('step5->passwordPlaceholder')}
                minLength={passwordMinLength}
                maxLength={passwordMaxLength}
                customValidator={(val) => {
                  if (val.length < 6 || !atLeastOneUppercaseAndLowercase(val) || !onlyAllowedSpecialCharacters(val)) {
                    return (
                      <div>
                        {t('createAccount:step2->error->passwordFormatTitle')}
                        <StyledErrorList>
                          <Trans
                            i18nKey="createAccount:step2->error->passwordFormatError"
                            components={[<li key={1}>{}</li>, <li key={2}>{}</li>, <li key={3}>{}</li>]}
                          />
                          {t('createAccount:step2->error->passwordFormatCharacters', {
                            interpolation: { escapeValue: false },
                          })}
                        </StyledErrorList>
                      </div>
                    );
                  }
                  return '';
                }}
              />

              <InputField
                type="password"
                name="repeatPassword"
                placeholder={t('step5->repeatPasswordPlaceholder')}
                value={formValues.repeatPassword}
                onChange={(e) => setFormValues({ ...formValues, repeatPassword: e.target.value })}
                required
                passwordVisibilityToggle
                aria-label={t('step5->repeatPasswordPlaceholder')}
                customValidity={isMatching}
                maxLength={passwordMaxLength}
              />

              <StyledCheckBox
                checked={formValues.rememberMe}
                onChange={(e) => setFormValues({ ...formValues, rememberMe: !formValues.rememberMe })}
                id="lostpassword-remember-me"
              >
                {t('step5->rememberMe')}
              </StyledCheckBox>
            </StyledContentWrapper>
          )}

          {step === 6 && (
            <StyledContentWrapper gap={28} alignment="center">
              <Text type="body">{t('step6->infoText')}</Text>
            </StyledContentWrapper>
          )}

          {errorMsg && step === 7 && (
            <StyledContentWrapper gap={28} alignment="center">
              <Text type="body">{errorMsg}</Text>
            </StyledContentWrapper>
          )}

          {errorMsg && step !== 7 && <ErrorMessage name="error-message">{errorMsg}</ErrorMessage>}
        </div>
      )}
    </>
  );
};

export default LostPassword;
