import { ChangeEvent, FormEvent, Key, useMemo, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { setIsLoggingIn } from '@slices/loginSlice';
import doLogin from '@api/login';
import logger from '@logger';
import encrypt from '@helpers/encrypt';
import {
  trackIdentifyCustomer,
  trackLoginError,
  trackLoginFlowAndSettings,
  trackLoginModalTabChange,
} from '@helpers/analyticsHelpers/trackLogin';
import useCustomer from '@hooks/useCustomer';
import formatSSN from '@helpers/formatSSN';
import { useAppDispatch } from '@hooks/useAppDispatch';
import useCustomRouter from '@hooks/useCustomRouter';
import useTranslation from 'next-translate/useTranslation';
import usePersistedCart from '@hooks/usePersistedCart';
import { restoreCart } from '@api/interfaces/cartApi';
import CART from '@helpers/cartKeys';
import useCart from '@hooks/useCart';
import useLocalStorage from '@hooks/useLocalStorage';
import config from '@config';
import paths from '@constants/paths';

interface UseLoginModalProps {
  onClose: (success?: boolean) => void;
  tab?: 'b2b' | 'b2c' | 'bankId';
}

const useLoginModal = ({ onClose, tab }: UseLoginModalProps) => {
  const { t } = useTranslation('login');
  const { customer, refreshCustomer } = useCustomer();
  const { cart, isCartEmpty, refreshCart } = useCart();
  const dispatch = useAppDispatch();
  const router = useCustomRouter();
  const { trigger } = usePersistedCart();
  const [, setBankIdTabLastSelected] = useLocalStorage(config.LOCAL_STORAGE_SELECTORS.bankIdTabLastSelected, false);

  const [activeTab, setActiveTab] = useState<'b2c' | 'b2b' | 'bankId'>(tab || (customer?.isB2BCustomer ? 'b2b' : 'b2c'));
  const activeTabWithoutBankId = useMemo(() => (activeTab === 'bankId' ? 'b2c' : activeTab), [activeTab]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [globalErrorMessage, setGlobalErrorMessage] = useState({ b2b: '', b2c: '' });
  const [formValues, setFormValues] = useState({
    b2b: {
      j_username: customer?.sapId ? customer?.sapId : activeTab === 'b2b' ? (router.query.username as string) : '',
      j_username_key: '',
      j_password: '',
      j_password_key: '',
    },
    b2c: {
      j_username: customer?.socialSecurityNumer
        ? customer?.socialSecurityNumer
        : activeTab === 'b2c'
        ? (router.query.username as string)
        : '',
      j_username_key: '',
      j_password: '',
      j_password_key: '',
    },
    j_remember_me: true,
  });

  const encryptSubmitData = async () => {
    const data = { ...formValues[activeTabWithoutBankId], j_remember_me: formValues.j_remember_me };
    const encryptedUsername = await encrypt(data.j_username);
    const encryptedPassword = await encrypt(data.j_password);

    data.j_password = encryptedPassword.str;
    data.j_password_key = encryptedPassword.key;
    data.j_username = encryptedUsername.str;
    data.j_username_key = encryptedUsername.key;

    return data;
  };
  const onSubmitError = (response: AxiosResponse<LoginResponseType>) => {
    setGlobalErrorMessage({ ...globalErrorMessage, [activeTab]: response.data.message });
    trackLoginError(t(response.data.message));
    setIsSubmitting(false);
  };

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    const data = await encryptSubmitData();
    const source = axios.CancelToken.source();

    try {
      dispatch(setIsLoggingIn(true));
      const r = await doLogin(data, source.token);
      if (r.data.login_successful === 'true') {
        await onLoginSuccess();
      } else {
        onSubmitError(r);
      }
    } catch (error: any) {
      logger.error({
        error: 'Login failed',
        message: error.message,
      });
    } finally {
      dispatch(setIsLoggingIn(false));
    }
  };

  const onTabChange = (key: Key, dontTrack?: boolean) => {
    trackLoginModalTabChange(key, dontTrack);
    setActiveTab(key as 'b2c' | 'b2b');
    router.replace(
      // @ts-ignore
      { pathname: router.pathname, query: { ...router.query, tab: key } },
      { pathname: key === 'b2b' ? paths.B2B_LOGIN : paths.USER_LOGIN },
      { shallow: true }
    );
    setGlobalErrorMessage({ b2b: '', b2c: '' });
    setBankIdTabLastSelected(key === 'bankId');
  };

  const onLoginSuccess = async () => {
    setIsSubmitting(false);

    const customerData = await refreshCustomer();

    customerData && trackIdentifyCustomer(customerData, false);
    trackLoginFlowAndSettings(activeTab === 'bankId' ? 'BankID' : 'password', formValues.j_remember_me);

    await refreshCustomer();
    const persistedCart = await trigger();
    if (isCartEmpty && cart?.slot === null) {
      const cartResponse = await restoreCart(persistedCart ? CART.KEEP_PERSISTENT : CART.KEEP_SESSION);
      await refreshCart(cartResponse.data);
    }
    onClose(true);
  };

  const handleCheckboxChange = (checked: boolean) => {
    setFormValues({ ...formValues, j_remember_me: checked });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [activeTab]: { ...formValues[activeTabWithoutBankId], [name]: value },
    });
  };

  const handleBlur = () => {
    setFormValues({
      ...formValues,
      [activeTab]: {
        ...formValues[activeTabWithoutBankId],
        j_username: formatSSN(formValues[activeTabWithoutBankId].j_username),
      },
    });
  };

  return {
    isSubmitting,
    activeTab,
    activeTabWithoutBankId,
    formValues,
    globalErrorMessage,
    handleInputChange,
    handleBlur,
    onTabChange,
    onSubmitHandler,
    handleCheckboxChange,
    onLoginSuccess,
  };
};

export default useLoginModal;
